<template lang="pug">
#enable-permissions.step-component

  h2
    icon.ep-img(
      data="@icon/permissions.svg"
      :fill="true"
      color="black #e1ddd8"
      width="12.8rem"
      height="12.8rem"
    )
    | Enable Video

  p For the best user experience, please provide access to your camera and microphone.

  p
    a(@click="openExplainer") Why do we need this?

  button.enable-media(v-if="acquiringMedia" :class="{ 'media-enabled': gotMedia }" @click="getMediaAccess")
    icon(v-if="gotMedia" data="@icon/check.svg" color="white")
    icon(v-else data="@icon/videocam.svg")
    span {{ buttonText }}

  button.button(v-else :disabled="!gotMedia" @click="gotoNext()") Continue
</template>

<script>
import AmplitudeAPI from '@/utils/amplitude'
import MixpanelAPI from '@/utils/mixpanel'
const {
  isSupported,
  createLocalVideoTrack,
  createLocalAudioTrack,
  LocalDataTrack,
} = require('twilio-video')

export default {
  props: {
    allowProceedWithoutVideo: {type: Boolean, default: false}
  },

  data() {
    return {
      gotVideo: false,
      gotAudio: false,
      acquiringMedia: true,
      stepTimeStampAmplitude: Date.now()
    }
  },

  computed: {
    buttonText,
    gotMedia,
  },

  watch: {},

  beforeCreate() {
    // Everything after this step must be in landscape mode, so don't unset it
    this.$app.forceLandscape = true
  },

  methods: {
    getMediaAccess,
    gotoNext,
    handleMediaError,
    openExplainer,
    openTryAgainModal,
    proceedWithoutVideo,
  },

  components: {},
}

/* Computed ---------------------------------------------------- */
function buttonText() {
  return this.gotMedia ? 'Video Enabled' : 'Enable Video'
}

function gotMedia() {
  return this.gotAudio && this.gotVideo
}

/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
async function getMediaAccess() {
  if (!isSupported) {
    this.$bugsnag.notify(new Error('Unsupported browser attempted to get media access'))
    this.$modal.open('UnsupportedBrowser')
    return
  }

  // Create local tracks
  try {
    const videoTrack = await createLocalVideoTrack({ facingMode: 'user' })
    this.$store.commit('twilio/SET_LOCAL_VIDEO_TRACK', videoTrack)
    this.gotVideo = true
  } catch (error) {
    this.handleMediaError(error)
    return
  }

  try {
    const audioTrack = await createLocalAudioTrack()
    this.$store.commit('twilio/SET_LOCAL_AUDIO_TRACK', audioTrack)
    this.gotAudio = true
  } catch (error) {
    this.handleMediaError(error)
    return
  }

  // show the successful media acquisition state of the button, then show the next button
  setTimeout(() => {
    this.acquiringMedia = false
  }, 1000)

  this.$store.commit('twilio/SET_LOCAL_DATA_TRACK', new LocalDataTrack())
}

function gotoNext() {
  this.$emit('complete')
}

function handleMediaError(error) {
  console.log('EnablePermissions.vue :121', error)
  this.$bugsnag.notify(error)

  if (error.name == 'NotAllowedError') {
    this.$modal.open('UpdateBrowserPermissions')
  } else {
    this.openTryAgainModal()
  }
}

function proceedWithoutVideo() {
  this.$store.commit('user/SET_VIDEO_PERMISSION', true)
  const eventTitle = 'ProceededWithoutVideo'
  const eventProperties = {
    kit_type: this.$store.getters['user/getKitType'],
    medplum_id: this.$store.getters['user/getMedplumOrderId'],
    barcode: this.$store.getters['user/getBarcodeId'],
    sku: this.$store.getters['user/getSku'],
    customer: this.$store.getters['user/getConfigKeys']
      ? this.$store.getters['user/getConfigKeys'].carrier
      : null,
    segment_time: Date.now() - this.stepTimeStampAmplitude,
    source: this.$route.query.src,
  }

  AmplitudeAPI.logEvent(eventTitle, {
    ...eventProperties,
    linked_amplitude_id: this.$route.query.q,
  })

  MixpanelAPI.track(eventTitle, {
    ...eventProperties,
    application: 'Collection App',
  })

  this.gotoNext()
}

function openExplainer() {
  const eventTitle = 'PermissionExplainer'
  const eventProperties = {
    kit_type: this.$store.getters['user/getKitType'],
    medplum_id: this.$store.getters['user/getMedplumOrderId'],
    barcode: this.$store.getters['user/getBarcodeId'],
    sku: this.$store.getters['user/getSku'],
    customer: this.$store.getters['user/getConfigKeys']
      ? this.$store.getters['user/getConfigKeys'].carrier
      : null,
    segment_time: Date.now() - this.stepTimeStampAmplitude,
    source: this.$route.query.src,
  }

  AmplitudeAPI.logEvent(eventTitle, {
    ...eventProperties,
    linked_amplitude_id: this.$route.query.q,
  })

  MixpanelAPI.track(eventTitle, {
    ...eventProperties,
    application: 'Collection App',
  })

  this.$modal.open('PermissionExplainer', {
    onAction: this.proceedWithoutVideo,
    enableVideo: this.getMediaAccess,
    allowProceedWithoutVideo: this.allowProceedWithoutVideo,
  })
}

function openTryAgainModal() {
  this.$modal.open('PermissionsTryAgain', {
    emailSubject: 'Kit app: Could not get mic/camera',
    retryAction: () => {
      this.getMediaAccess()
    },
  })
}
</script>
